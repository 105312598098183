<!-- 考试大类编辑 -->
<template>
  <div class="editExamBigClass">
    <!-- 上面部分 -->
    <div class="top">
      <span style="font-size: 16px; font-weight: bold">考试大类编辑</span>
      <el-button class="el-btn" size="small" @click="goBack"> 返回 </el-button>
    </div>
    <!-- 表单部分 -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      id="form-1"
      class=""
      inline
      label-width="150px"
    >
      <el-form-item label="编号">
        <el-input
          v-model="form.number"
          placeholder="请输入编号"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="设置日期">
        <el-date-picker
          v-model="form.setDate"
          type="date"
          placeholder="请选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="考试名称" prop="testName">
        <el-input
          v-model="form.testName"
          placeholder="请输入考试名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="开始日期" prop="startDate">
        <el-date-picker
          v-model="form.startDate"
          type="date"
          placeholder="请选择开始日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="截止日期" prop="endDate">
        <el-date-picker
          v-model="form.endDate"
          type="date"
          placeholder="请选择截止日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态">
        <div style="width: 300px">
          <el-radio-group v-model="form.choice">
            <el-radio :label="1">准备</el-radio>
            <el-radio :label="2">完成</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="报名开始日期">
        <el-date-picker
          v-model="form.startSign"
          type="date"
          placeholder="请选择报名开始日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="报名截止日期">
        <el-date-picker
          v-model="form.endSign"
          type="date"
          placeholder="请选择报名截止日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="准考证发布状态">
        <div style="width: 300px">
          <el-radio-group v-model="form.condition">
            <el-radio :label="1">发布</el-radio>
            <el-radio :label="2">未发布</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="说明">
        <el-input
          v-model="form.explain"
          type="textarea"
          class="textArea-width"
          placeholder=""
        ></el-input>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <!-- 底部 提交与取消 -->
    <div slot="footer" class="dialog-footer">
      <el-button
        style="background-color: #18bc9c; color: #ffffff"
        @click="submitForm('form')"
        >提 交</el-button
      >
      <el-button class="btn" @click="resetForm('form')">取 消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        number: "", //编号
        setDate: "", //设置日期
        testName: "", //考试名称
        startDate: "", //开始日期
        endDate: "", //截止日期
        choice: 1, //类型
        startSign: "", //报名开始日期
        endSign: "", //报名截止日期
        condition: 1, //准考证发布状态
        explain: "", //说明
      },
      rules: {
        testName: [
          {
            required: true,
            message: "考试名称不能为空",
            trigger: "blur",
          },
        ],
        startDate: [
          {
            required: true,
            message: "开始日期不能为空",
            trigger: "change",
          },
        ],
        endDate: [
          {
            required: true,
            message: "截止日期不能为空",
            trigger: "change",
          },
        ],
      },
      bool: false,
    };
  },
  created() {
    // 初次渲染
    this.$request({
      url: "/api/ExamCategory/detail",
      method: "POST",
      data: {
        id: this.$route.query.id,
      },
    }).then((res) => {
      if (res.code == 1) {
        console.log(res);
        let data = res.data.data;
        let form = this.form;
        form.number = data.number; //编号
        form.setDate = data.category_date; //设置日期
        form.testName = data.exam_name; //考试名称
        form.startDate = data.start_date; //开始日期
        form.endDate = data.end_date; //截止日期
        form.choice = data.status; //类型
        form.startSign = data.enroll_date; //报名开始日期
        form.endSign = data.enroll_date2; //报名截止日期
        form.condition = data.certificate_status; //准考证发布状态
        form.explain = data.remark; //说明
      }
    });
  },
  methods: {
    goBack() {
      //返回
      this.$router.go(-1);
    },
    submitForm(form) {
      //提交
      this.$refs[form].validate((valid) => {
        if (valid) {
          // alert('submit!');
          if (this.bool) {
            return;
          }
          this.bool = true;
          this.$request({
            url: "/api/ExamCategory/edit",
            method: "POST",
            data: {
              number: this.form.number,
              category_date: this.form.setDate,
              exam_name: this.form.testName,
              start_date: this.form.startSign,
              end_date: this.form.endDate,
              status: this.form.choice,
              enroll_date: this.form.startSign,
              enroll_date2: this.form.endSign,
              certificate_status: this.form.condition,
              remark: this.form.explain,
              id: this.$route.query.id,
            },
          })
            .then((res) => {
              // console.log(res)
              if (res.code == 1) {
                this.$message({
                  message: "编辑列表成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1500);
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1500);
              }
            })
            .catch(() => {
              this.bool = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(form) {
      //取消
      this.goBack();
      // this.$refs[form].resetFields();
    },
  },
};
</script>

<style scoped="scoped">
.editExamBigClass {
  width: 100%;
  height: 100%;
}

/* 上面部分 */
.top {
  padding-top: 25px;
  margin: 0px 24px 30px 28px;
  display: flex;
  justify-content: space-between;
}

.el-btn {
  background: #18bc9c;
  padding-left: 25px;
  background-position: 5px 7px;
  display: inline-block;
  background-image: url(../../assets/back.png);
  background-repeat: no-repeat;
  color: #ffffff;
}

/* 表单部分 */
.el-form {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

::v-deep .el-form-item__label {
  color: #000000;
}

.el-input {
  width: 300px;
  height: 37px !important;
}

.el-select {
  width: 300px;
}
#form-1 ::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #fff !important;
}
/* 选择按钮 */
#form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #18bc9c;
}

#form-1 ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #18bc9c;
}

#form-1 ::v-deep .el-radio__inner:hover {
  border-color: #18bc9c;
}

/* 底部提交 取消 */
.dialog-footer {
  text-align: center;
  padding-bottom: 20px;
}

.btn:hover {
  color: #666;
  border-color: #ccc;
  background-color: #ffffff;
}
</style>
